import { useTranslation } from 'react-i18next';
import { useExternalLink } from '../../i18n/useExternalLink';
import './Buttons.css'
import { Button } from './Buttons';

export const ContactButton = ({children, ...rest}) => {

    const { contactUrl, contactEmail } = useExternalLink()
    const { i18n } = useTranslation();
    let contactLink = contactUrl
    switch (i18n.language) {
        case 'fr': {
            contactLink = `mailto:${contactEmail}?subject=Demande de démo DreamAudit`
            break
        }
        default: {
            contactLink = contactUrl
        }
    }
    return (
        <Button variant="primary" {...rest} target="_blank" href={contactLink}>
            {children}
        </Button>
    )
}