
import PrimaryButton from '../../atoms/Button/Buttons'
import logo from '../../assets/logo.svg';
import linkedinLogo from './assets/linkedin.svg';
import youtubeLogo from './assets/youtube.svg';
import './Footer.css'
import { scrollTo } from '../../Utils';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../atoms/LanguageSwitcher/LanguageSwitcher';
import { useExternalLink } from '../../i18n/useExternalLink';

export const Footer = () => {
    const { t } = useTranslation();
    const { aboutUsUrl, clientAppUrl, contactEmail, contactUrl, linkedinUrl, youtubeUrl, } = useExternalLink()
    return (
        <section id="footer" className="footer full-height">
            <div className="section-description">
                <h2>
                    {t('footer.title')}
                </h2>
                <p dangerouslySetInnerHTML={{__html: t('footer.description', {interpolation: {escapeValue: false}})}}></p>
                <PrimaryButton
                    size="large"
                    href={contactUrl}
                    eventName="book_demo_footer"
                    target="_blank">
                    {t('common.book_demo')}
                </PrimaryButton>
            </div>
            <div className="cloud">
                <div className="cloud-part left"></div>
                <div className="cloud-part main"></div>
                <div className="cloud-part right"></div>
                <div className="mask"></div>
            </div>
            <footer>
                <div className="row">
                    <a className="logo" onClick={scrollTo} href="hero-section">
                        <img src={logo} alt={t("common.logo")} />
                    </a>
                    <div>
                        <ul className="social-links">
                            <li>
                                <a  href={youtubeUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="social-link">
                                    <img src={youtubeLogo} alt="Youtube logo" />
                                </a>
                            </li>
                            {/*<li>
                                <a  href={process.env.REACT_APP_TWITTER_URL}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="social-link">
                                    
                                </a>
                            </li>*/}
                            <li>
                                <a  href={linkedinUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="social-link">
                                    <img src={linkedinLogo} alt="Linkedin logo" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <ul className="legal">
                        {/* <li>
                            <a href="">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="">
                                Legal mentions
                            </a>
                        </li> */}
                        <li>
                            {t('footer.copyright')}
                        </li>
                    </ul>
                    <ul className="nav-links">
                        <li>
                            <a href={aboutUsUrl} target="_blank" rel="noreferrer">
                                {t('navigation.about_us')}
                            </a>
                        </li>
                        <li>
                            <a href={`mailto:${contactEmail}`}>
                                {t('navigation.contact_us')}
                            </a>
                        </li>
                        <li>
                            <a href={clientAppUrl} >
                                {t('navigation.sign_in')}
                            </a>
                        </li>
                        <li>
                            <LanguageSwitcher />
                        </li>
                    </ul>
                </div>
            </footer>
        </section>
    )
}